import { GlobalState } from '../../../global/types';
import { selectCurrentMessageList } from '../../../global/selectors/messages';

export function selectCurrentChatExport(global: GlobalState) {
  const { chatId } = selectCurrentMessageList(global) || {};
  if (!chatId) {
    return undefined;
  }

  const currentChat = global.chatExport.byChatId[chatId];
  if (!currentChat || !currentChat.isActive) {
    return undefined;
  }

  return currentChat;
}
